import React, { useState } from 'react';
import AddButton from './AddButton';
import SelectShowRecords from '../SelectShowRecords';
import PaginationCustom from '../PaginationCustom';
import LoaderContent from '../../components/LoaderContent';
import { CustomTable } from './CustomTable/CustomTable';
import SearchItemsInput from '../SearchItemsInput';
import { Button } from 'react-bootstrap'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { useMigrationEmailTemplateMutation } from '../../store/apis/notificationApi';
import { useMigrationDealsButtonTemplateMutation } from '../../store/apis/dealsApi';
import { useTranslation } from 'react-i18next';
 
const ControlPanelsV2 = ({setShowAddModal, showAddModal, buttons, setButton, activeButton, totalCount, totalPages, isLoading, rows, columns, changeItem, isModal, selectedItems, setItemsFromModal, addElementComponent, query, setQuery, fromPage}) => {
	
	const [currentPage, setCurrentPage] = useState(1)
	// eslint-disable-next-line no-unused-vars
	const [limit, setLimit] = useState(20)
	const [search, setSearch] = useState(undefined)
	const [confirmModal, setConfirmModal] = useState(false)
	const [successModal, setSuccessModal] = useState(false)

	const [migrationEmailTemplatesQuery, {isLoading: isMigrationLoading}] = useMigrationEmailTemplateMutation()
	const [migrationDealsButtonTemplates, {isLoading: isMigrationDealsTempllatesLoading}] = useMigrationDealsButtonTemplateMutation()

	const {t} = useTranslation()
	
	async function migrationToProd(){
		let func = migrationEmailTemplatesQuery
		if(fromPage === "emailTemplates") func = migrationEmailTemplatesQuery
		if(fromPage === "dealsMessagess") func = migrationDealsButtonTemplates
		const response = await func()
		if(response.data.status === 200){
			setSuccessModal(response?.data?.data?.length || response?.data?.result?.length)
		}
	}

	return (
		<div className='terms-wrapper'>
			<div className='d-flex align-items-center'>
				<AddButton
					title={t('create')}
					handler={()=>setShowAddModal()}
				/>
				{
					isModal ?
					<AddButton
						title={t('add')}
						handler={()=>setItemsFromModal()}
					/>
					: null
				}
				{
					(fromPage === 'emailTemplates' || fromPage === 'dealsMessagess') ?
					(
						<div className='absolute_button'>
							<Button onClick={()=>setConfirmModal(true)}>{t('copy_to_prod')}</Button>
							{
								confirmModal ? 
								<ConfirmModal 
									title={t('copy_to_prod')}
									text={t('confirm_copy_to_prod')}
									close={()=>setConfirmModal(false)}
									success={migrationToProd}
									isLoading={isMigrationLoading || isMigrationDealsTempllatesLoading}
								/> : null
							}
							{
								(successModal || typeof successModal === 'number') ? 
								<ConfirmModal 
									title={t('success')}
									text={`${t('moved_prod')} ${successModal} ${t('elements')}.`}
									close={()=>setSuccessModal(false)}
								/> : null
							}
						</div>
					) : null
				}
			</div >
			<div className='terms-wrapper_search'>
				<SearchItemsInput
					searchHandler={(value)=>{console.log(value)}}
					toggleModalSearch={()=>{ setQuery(prev => {return {...prev, search: search}})}}
					setSearch={(value)=>{console.log(value)}}
					valueSearchInputHandler={(value)=>setSearch(value)}
				/>
				{
					1 > 2
					&&
					<>
					<PaginationCustom
						itemsCount={totalCount}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						counterAllItems={totalPages}
					/>
					<SelectShowRecords setCountShow={setLimit} />
					</>
				}
			</div >
			{
				buttons?.length?
				(
					<div className='terms-wrapper_search-types' style={{marginBottom: 20}}>
						{
							buttons?.map((button, index) => {
								return(
									<button key={index} type='button' className={`terms-wrapper_search-types_button ${activeButton === button.value? 'term-types_active' : ''}`} onClick={() => setButton(button.value)}>
										{button.title}
									</button >
								)
							})
						}
					</div>
				) : null
			}
			{
				isLoading
					?
					<LoaderContent />
					:
					rows?.length ?
					<CustomTable 
						rows={rows} 
						columns={columns} 
						totalCount={totalCount} 
						changeItem={changeItem}
						selectedItems={selectedItems}
					/>
					: <p className='userInfo-wrapper_block-pib_name mt-5'>{t('no_data')}</p >
			}
			{showAddModal ? addElementComponent : null}
			
		</div >
	)
}

export default ControlPanelsV2
