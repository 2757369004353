import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import { Formik } from "formik"
import {
	Button,
	Form
} from "react-bootstrap"
import Loader from "../../../components/Loader"
import NameBlockTemplateContent from "./NameBlockTemplateContent"
import TypeBlockTemplateContent from "./TypeBlockTemplateContent"
import ValueBlockTemplateContent from "./ValueBlockTemplateContent"
import {
	useDispatch,
	useSelector
} from "react-redux"
import {
	createNewTemplateContentItem,
	deleteDateChangeUnitTemplatesList,
	editTemplateContentItem, resetDateChangeUnitTemplates,
	resetDateChangeUnitTemplatesList,
	setDateChangeUnitTemplates,
	setDateChangeUnitTemplatesList,
	showChangeUnitModalTemplates
} from "../../../store/slices/temlateContentSlice"
import {
	useCreateTemplateContentMutation,
	useEditTemplateContentMutation
} from "../../../store/apis/temlateContentApi"
import AlertModal from "../../../components/AlertModal"
import LoaderContent from "../../../components/LoaderContent"
import { UniqueArrItems } from "../../../../utils/helpers/UniqueArrItems"
import {
	showModalEditDataTemplateContentTemplates,
	showModalEditTemplateContentTemplates
} from "../../../store/slices/templateSlice"
import FourBlockTemplateContent from "./FourBlockTemplateContent"
import ValidationRulesRegularBuilder from "./ValidationRules/ValidationRulesRegularBuilder"
import { useGetTermsMutation } from '../../../store/apis/termsApi'
import { useTranslation } from 'react-i18next'

const initialState = {
	name: {},
	psevdo: '',
	// changeType: 1,
	// description: {},
	// placeholder: {},
	type: 1,
	required: false,
	values: [],
	unit: undefined,
	key: 'content',
	validationRules: []
}

const AddEditBuilderTemplateContent = ({
	onHide,
	edit,
	editDateForModal
}) => {
	const [form, setForm] = useState(edit ? editDateForModal : initialState)
	const [updatedState, setUpdatedState] = useState(1)
	const [createTemplateContent, {isLoading: isCreateTemplateContentLoading}] = useCreateTemplateContentMutation()
	const [editTemplateContent, {isLoading: isEditTemplateContentLoading}] = useEditTemplateContentMutation()
	const [getTerms, {isLoading: isTermsLoading}] = useGetTermsMutation()
	const {t} = useTranslation()
	console.log("editDateForModal", editDateForModal)
	
	const {
		showModalChangeUnitTemplates,
		dateModalChangeUnitTemplates,
		dateModalChangeUnitTemplatesList
	} = useSelector(state => state.templateContentStore)
	const {unitGroups} = useSelector((state) => state.unitGroupsStore)
	const {mark} = useSelector((state) => state.markStore)
	const {model} = useSelector(state => state.modelStore)
	const {terms} = useSelector((state) => state.termsStore)
	const langs = useSelector(store => store.langStore.langs)
	const [changeItem, setChangeItem] = useState({})
	const [changeItemsArr, setChangeItemsArr] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	const dispatch = useDispatch()

	const isLoading =
		isCreateTemplateContentLoading ||
		isEditTemplateContentLoading ||
		isTermsLoading


	useEffect(()=>{
		if(edit && (editDateForModal?.type === 2 || editDateForModal?.type === 3)){
			getTerms({list: [...editDateForModal.values?.map(el => el.id)]})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[edit]);

	useEffect(()=>{
		setForm({...form, description: {}, placeholder: {}})
		return ()=>{
			clearFormHideModal()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);
	
	const getAllUnitsMeasurement = useCallback(async () => {
		try {
			setForm({
				...editDateForModal,
				// values: [...editDateForModal?.values?.map(item => Number(item))],
				validationRules: editDateForModal?.validationRules?.map((item, index) => {
					const teh = Date.now() + index
					return {tehId: teh, ...item}
				})
			})
			edit && dispatch(setDateChangeUnitTemplates(editDateForModal?.unit))
			for (let i = 0; i < editDateForModal?.values?.length; i++) {
				dispatch(setDateChangeUnitTemplatesList(Number(editDateForModal?.values[i])))
			}
		} catch (e) {
			setErrorMessage(e)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit])

	useEffect(() => {
		edit && getAllUnitsMeasurement()
		edit && setForm({
			...editDateForModal,
			values: {...editDateForModal?.values},
			validationRules: editDateForModal?.validationRules?.map((item, index) => {
				const teh = Date.now() + index
				return {tehId: teh, ...item}
			})
		})
		edit && dispatch(setDateChangeUnitTemplates(editDateForModal?.unit))
		for (let i = 0; i < editDateForModal?.values?.length; i++) {
			dispatch(setDateChangeUnitTemplatesList(Number(editDateForModal?.values[i]?.id)))
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [edit])

	const showModalPr = () => dispatch(showChangeUnitModalTemplates(true))
	const toggleTypeChange = (type) => {
		// setTypeChange(type)
	}
	const formDateUpdateHandler = (opt) => {
		setForm({...form, ...opt})
	}

	const filterItemHelper = (id, arr) => arr?.filter(item => item.id === Number(id))

	useEffect(() => {
		let teh = []
		for (let i = 0; i < dateModalChangeUnitTemplatesList?.length; i++) {
			teh = [...teh, ...filterItemHelper(dateModalChangeUnitTemplatesList[i], terms)]
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateModalChangeUnitTemplatesList, edit, isLoading])

	const handleSubmit = async (values, {resetForm}) => {
		let arrValues = UniqueArrItems(dateModalChangeUnitTemplatesList)
		arrValues = arrValues.filter(el => el)
		const resValidationRules = form?.validationRules?.map(item =>{
			const res = {
				type: item?.type,
				value: item?.value,
				description: item?.description
			}
			return res
		})
		
		const psevdoRes = form.psevdo?.trim() || ''
		const titles = {}
		const descriptions = {}
		const placeholders = {}
		let description_flag = false
		let placeholder_flag = false
		let error = false;
		langs?.forEach(el => {
			titles[`${el.langIndex}`] = form.name[el.langIndex]
			descriptions[`${el.langIndex}`] = form.description?.[`${el.langIndex}`]
			placeholders[`${el.langIndex}`] = form.placeholder?.[`${el.langIndex}`]
		})
		Object.entries(descriptions).forEach(el => {
			if(el[1] && el[1].length) description_flag = true
		})
		Object.entries(placeholders).forEach(el => {
			if(el[1] && el[1].length) placeholder_flag = true
		})
		
		if(description_flag){
			Object.entries(descriptions).forEach(el => {
				if(!el[1] && !el[1]?.length) error = t('c_tcd')
			})
		}
		if(placeholder_flag){
			Object.entries(placeholders).forEach(el => {
				if(!el[1] && !el[1]?.length) error = t('c_tcp')
			})
		}
		if(error){
			setErrorMessage(error)
			return
		}
		const type = Number(form?.type)
		const formDate = {
			type: type,
			values: arrValues,
			name: titles,
			description: description_flag ? descriptions : undefined,
			placeholder: placeholder_flag ? placeholders: undefined,
			required: form.required || false,
			unit: Number(form.unit) || undefined,
			allowUserOptions: values.allowUserOptions || false,
			key: form?.key || editDateForModal?.key,
			validationRules: resValidationRules,
			defaultSort: form.defaultSort? form.defaultSort : false
		}
		const formDateUpdate = {
			type: type,
			values: arrValues,
			name: titles,
			description: description_flag? {title: descriptions} : undefined,
			placeholder: placeholder_flag? {title: placeholders} : undefined,
			required: form.required || false,
			unit: Number(form.unit) || undefined,
			allowUserOptions: values.allowUserOptions || false,
			key: form?.key || editDateForModal?.key,
			validationRules: resValidationRules,
			defaultSort: form.defaultSort? form.defaultSort : false
		}
		
		const psevdoPar =
			(edit && (editDateForModal?.psevdo !== form?.psevdo))
				?
				{...formDate, psevdo: psevdoRes}
				:
				{...formDate}
		const psevdoParUpdate =
			(edit && (editDateForModal?.psevdo !== form?.psevdo))
				?
				{...formDateUpdate, psevdo: psevdoRes}
				:
				{...formDateUpdate}

		try {
			if (edit && !form?.dublicate) {
				const {error} = await editTemplateContent({
					...psevdoParUpdate,
					id: editDateForModal.id
				})
				error && setErrorMessage(error?.data?.message)
				dispatch(editTemplateContentItem({
					...psevdoParUpdate,
					id: editDateForModal.id
				}))
				!error && resetForm()
				!error && clearFormHideModal()
			} else {
				const {data, error} = await createTemplateContent({...psevdoPar, psevdo: `${psevdoRes}_copy`})
				error && setErrorMessage(error?.data?.message)
				!error && dispatch(createNewTemplateContentItem({
					...psevdoPar,
					id: data?.id
				}))
				!error && resetForm()
				!error && clearFormHideModal()
			}
		} catch (e) {
			setErrorMessage(e)
		}
	}

	const clearFormHideModal = () => {
		onHide()
		setForm(initialState)
		dispatch(resetDateChangeUnitTemplatesList())
		dispatch(showModalEditDataTemplateContentTemplates({}))
		dispatch(showModalEditTemplateContentTemplates(false))
		dispatch(resetDateChangeUnitTemplates())
	}

	const findItemHelper = (id) => unitGroups?.find(item => item.id === Number(id))

	useEffect(() => {
		const teh = findItemHelper(dateModalChangeUnitTemplates)
		setChangeItem(teh)
		formDateUpdateHandler({
			unit: dateModalChangeUnitTemplates
		})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateModalChangeUnitTemplates, edit, unitGroups])

	useEffect(() => {
		let teh = []
		for (let i = 0; i < dateModalChangeUnitTemplatesList?.length; i++) {
			teh = [...teh, ...filterItemHelper(dateModalChangeUnitTemplatesList[i], terms)]
		}
		if (form?.key === 'mark') {
			for (let i = 0; i < dateModalChangeUnitTemplatesList?.length; i++) {
				teh = [...teh, ...filterItemHelper(dateModalChangeUnitTemplatesList[i], mark)]
			}
		}
		if (form?.key === 'model') {
			for (let i = 0; i < dateModalChangeUnitTemplatesList?.length; i++) {
				teh = [...teh, ...filterItemHelper(dateModalChangeUnitTemplatesList[i], model)]
			}
		}
		setChangeItemsArr(UniqueArrItems(teh))
		if (edit && form?.values?.length >= 1) {
			setForm({
				...form,
				values: [...editDateForModal?.values]
			})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModalChangeUnitTemplates, dateModalChangeUnitTemplatesList, edit, isLoading, updatedState])

	const removeChangeItem = () => {
		formDateUpdateHandler({unit: undefined})
		setChangeItem({})
	}
	const removeChangeItemArr = (id) => {
		const del = changeItemsArr?.filter(item => Number(item.id) !== Number(id))
		if (edit) {
			setChangeItemsArr(del)
			dispatch(deleteDateChangeUnitTemplatesList(id))
		} else {
			setChangeItemsArr(del)
			dispatch(deleteDateChangeUnitTemplatesList(id))
		}
	}

	useEffect(() => {
		!edit && dispatch(resetDateChangeUnitTemplatesList())
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form?.key])

	return (
		<div className='template-content'>
			{
				errorMessage
				&& <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			<h1 className='terms-title'>
				{(edit && form.dublicate) ? t('tf_dublicate_title') : edit? t('tf_edit_title') : t('tf_create_title')}
			</h1 >
			<Formik
				initialValues={{
					uaTitle: form?.uaTitle || form?.name?.ua || '',
					ruTitle: form?.ruTitle || form?.name?.ru || '',
					enTitle: form?.enTitle || form?.name?.en || '',
					plTitle: form?.plTitle || form?.name?.pl || '',

					uaDescription: form?.uaDescription || form?.description?.ua || '',
					ruDescription: form?.ruDescription || form?.description?.ru || '',
					enDescription: form?.enDescription || form?.description?.en || '',
					plDescription: form?.plDescription || form?.description?.pl || '',

					uaPlaceholder: form?.uaPlaceholder || form?.placeholder?.ua || '',
					ruPlaceholder: form?.ruPlaceholder || form?.placeholder?.ru || '',
					enPlaceholder: form?.enPlaceholder || form?.placeholder?.en || '',
					plPlaceholder: form?.plPlaceholder || form?.placeholder?.pl || '',

					psevdo: form?.psevdo || '',
					changeType: form?.changeType || form?.type || 1,
					required: form?.required || false,
				}}
				onSubmit={handleSubmit}
			>
				{
					({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isValid,
						handleSubmit,
						dirty
					}) => (
						<Form
							className='add-edit-terms'
							onSubmit={handleSubmit}
						>
							<NameBlockTemplateContent
								handleBlur={handleBlur}
								values={form?.name}
								psevdoValue={form?.psevdo}
								touched={touched}
								errors={errors}
								handleChange={handleChange}
								formDateUpdateHandler={formDateUpdateHandler}
								setForm={setForm}
							/>
							<TypeBlockTemplateContent
								handleBlur={handleBlur}
								values={values}
								touched={touched}
								errors={errors}
								handleChange={handleChange}
								formDateUpdateHandler={formDateUpdateHandler}
								form={form}
								setForm={setForm}
								edit={edit}
								setChangeItemsArr={setChangeItemsArr}
							/>
							{
								(
									form?.type === 1 || form?.type === 6
								)
								&&
								<>
									{
										isLoading ? <LoaderContent /> : <ValueBlockTemplateContent
											handleBlur={handleBlur}
											values={values}
											touched={touched}
											errors={errors}
											handleChange={handleChange}
											formDateUpdateHandler={formDateUpdateHandler}
											type={1}
											form={form}
											showModalChangeUnitTemplates={showModalChangeUnitTemplates}
											showModalPr={showModalPr}
											changeItemsArr={changeItemsArr}
											removeChangeItemArr={removeChangeItemArr}
											changeItem={changeItem}
											removeChangeItem={removeChangeItem}
											toggleTypeChange={toggleTypeChange}
										/>
									}
								</>
							}
							{
								(
									form?.type === 2
								)
								&&
								<>
									{
										isLoading ? <LoaderContent /> : <ValueBlockTemplateContent
											handleBlur={handleBlur}
											values={values}
											touched={touched}
											errors={errors}
											handleChange={handleChange}
											formDateUpdateHandler={formDateUpdateHandler}
											type={
												form?.key !== 'mark' ? form?.key !== 'model' ? 2 : 5 : 4
											}
											form={form}
											showModalChangeUnitTemplates={showModalChangeUnitTemplates}
											dateModalChangeUnitTemplatesList={dateModalChangeUnitTemplatesList}
											showModalPr={showModalPr}
											changeItemsArr={changeItemsArr}
											removeChangeItemArr={removeChangeItemArr}
											changeItem={changeItem}
											removeChangeItem={removeChangeItem}
											toggleTypeChange={toggleTypeChange}
											setUpdatedState={setUpdatedState}
											edit={edit}
										/>
									}
								</>
							}
							{
								(
									form?.type === 3
								)
								&&
								<>
									{
										isLoading ? <LoaderContent /> : <ValueBlockTemplateContent
											handleBlur={handleBlur}
											values={values}
											touched={touched}
											errors={errors}
											handleChange={handleChange}
											formDateUpdateHandler={formDateUpdateHandler}
											type={3}
											form={form}
											showModalChangeUnitTemplates={showModalChangeUnitTemplates}
											showModalPr={showModalPr}
											changeItemsArr={changeItemsArr}
											removeChangeItemArr={removeChangeItemArr}
											changeItem={changeItem}
											removeChangeItem={removeChangeItem}
											toggleTypeChange={toggleTypeChange}
											setUpdatedState={setUpdatedState}
											edit={edit}
										/>
									}
								</>
							}
							<FourBlockTemplateContent
								handleBlur={handleBlur}
								valuesPlaceholder={form?.placeholder}
								valuesDescription={form?.description}
								touched={touched}
								errors={errors}
								handleChange={handleChange}
								formDateUpdateHandler={formDateUpdateHandler}
								setForm={setForm}
							/>

							{
								(
									form?.type === 4
								)
								&&
								<ValidationRulesRegularBuilder
									form={form}
									edit={edit}
									handleBlur={handleBlur}
									values={form.validationRules}
									touched={touched}
									errors={errors}
									handleChange={handleChange}
									formDateUpdateHandler={formDateUpdateHandler}
								/>
							}

							<div className='template-content_buttons'>
								<Button
									type='submit'
									className='template-content_buttons-save'
									disabled={(
										!isValid && dirty
									)}
									onClick={handleSubmit}
								>
									{
										isLoading ? <Loader /> : t('save')
									}
								</Button >
								<Button
									type='button'
									className='template-content_buttons-close'
									onClick={clearFormHideModal}
								>
									{t('cancel')}
								</Button >
							</div >
						</Form >
					)}
			</Formik >
		</div >
	)
}

export default AddEditBuilderTemplateContent