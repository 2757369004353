import React, {
	useCallback,
	useEffect,
	useState
} from 'react'
import AddButton from "./AddButton"
import LoaderContent from "../LoaderContent"
import TermsTabl from "../../pages/Terms/TermsTabl"
import PaginationCustom from "../PaginationCustom"
import { arrayBreakdownHelper } from "../../../utils/helpers/ArrayBreakdownHelper"
import { searchItemsHelper } from "../../../utils/helpers/SearchItemsHelper"
import {
	useDispatch
} from "react-redux"
import { showChangeUnitPriceModal, showModalSubChangeUnitPrice } from "../../store/slices/priceSlice"
import { showChangeUnitAmountModal } from "../../store/slices/amountSlice"
import { showChangeUnitModalTemplates } from "../../store/slices/temlateContentSlice"
import SearchItemsInput from "../SearchItemsInput"
import { useGetTermsMutation } from "../../store/apis/termsApi"
import { setUnitsMeasurementList, showChangeUnitModal } from "../../store/slices/unitsMeasurementSlice"
import { showChangeTermsModal } from "../../store/slices/termsSlice"
import TemplateTabl from "../../pages/Templates/TemplateTabl"
import { useGetHtmlsMutation, useMigrationHtmlsMutation } from "../../store/apis/htmlsApi"
import { showChangeHTMLSModal } from "../../store/slices/htmlsSlice"
import {
	getUnitGroupsList,
	showChangeUnitGroupModalHandler,
	showChangeUnitGroupsModal
} from "../../store/slices/unitGroupsSlice"
import { showChangeMark } from "../../store/slices/markSlice"
import { showChangeMarkForModelModel } from "../../store/slices/modelSlice"
import { useGetMarkMutation } from "../../store/apis/markApi"
import { useGetTransportCategoryMutation } from "../../store/apis/transportCategoryApi"
import { useGetBodyTypeMutation } from "../../store/apis/bodyTypeApi"
import { showChangeBodyType } from "../../store/slices/bodyTypeSlice"
import { showChangeTransportCategory } from "../../store/slices/transportCategorySlice"
import { ARE_COMMON } from "../../../utils/textsContent"
import SelectShowRecords from "../SelectShowRecords"
import { filteredTermsType } from "../../../utils/helpers/FilteredTemplatesContentType"
import AlertModal from "../AlertModal"
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { ConfirmModal } from '../ConfirmModal/ConfirmModal'
import { useMigrationSubcategoriesToProdMutation } from '../../store/apis/subcategoryApi'
import { useMigrationCategoriesToProdMutation } from '../../store/apis/categoryApi'
import { useTranslation } from 'react-i18next'

const ControlPanels = ({
	bigArrElements,
	showModalHandler,
	isLoading,
	openEditModalHandler,
	toggleDeleteModal,
	showModalAddUnitHandler,
	fromPage,
	addEditModalShow,
	searchTermValue,
	loaderSorting,
	setLoaderSorting,
	toggleModalSearch,
	showModalSearch,
	showSearchBeforeDel,
	setShowSearchBeforeDel,
	valueSearchInputHandler,
	setShowModalPrice,
	setShowModalAmount,
	setItem,
	setOrder = ()=>{}
}) => {
	const [smallAllArrTerms, setSmallAllArrTerms] = useState([])
	const [showSmallTermArr, setShowSmallTermArr] = useState([])
	const [countShowTerms, setCountShowTerms] = useState(20)
	const [currentPage, setCurrentPage] = useState(1)
	const [countShowPage, setCountShowPage] = useState(0)
	const [counterAllItems, setCounterAllItems] = useState(0)
	const [showPaginationBoard, setShowPaginationBoard] = useState(true)
	const [markerChangeTab, setMarkerChangeTab] = useState('all')
	const [arrForSearchInChangeTab, setArrForSearchInChangeTab] = useState([])
	const [errorMessage, setErrorMessage] = useState('')
	const [confirmModal, setConfirmModal] = useState(false)
	const [successModal, setSuccessModal] = useState(false)
	const [getTerms, {isLoading: isTermsLoading}] = useGetTermsMutation()
	const [getHtmls, {isLoading: isHtmlsLoading}] = useGetHtmlsMutation()
	const [getMark, {isLoading: isGetMarkLoading}] = useGetMarkMutation()
	const [getTransportCategory, {isLoading: isGetTransportCategoryLoading}] = useGetTransportCategoryMutation()
	const [getBodyType, {isLoading: isGetBodyTypeLoading}] = useGetBodyTypeMutation()
	const allButtonsType = document.querySelectorAll('.terms-wrapper_search-types_button')
	const dispatch = useDispatch()
	const {t} = useTranslation()

	const unitlist = useSelector(store => store.unitsMeasurementStore.unitsMeasurement)
	const unitgroups = useSelector(store => store.unitGroupsStore.unitGroups)
	const [migrationSubcategories, {isLoading: isMigrationSubcategoriesLoading}] = useMigrationSubcategoriesToProdMutation()
	const [migrationCategories, {isLoading: isMigrationCategoriesLoading}] = useMigrationCategoriesToProdMutation()
	const [migrationHtmls, {isLoading: isMigrationHtmlsLoading}] = useMigrationHtmlsMutation()
	
	const isLoadingReq =
		loaderSorting ||
		isTermsLoading ||
		isHtmlsLoading ||
		isGetMarkLoading ||
		isGetTransportCategoryLoading ||
		isGetBodyTypeLoading

	async function migrationToProd(){
		let response
		if(fromPage === "subcategory") response = await migrationSubcategories()
		if(fromPage === "category") response = await migrationCategories()
		if(fromPage === "htmls") response = await migrationHtmls()

		if(response.error){
			setErrorMessage(response.error.error)
		}
		else if(response.data.status === 200){
			setSuccessModal(response.data.result.length)
		}
	}

	const getAllUnitsMeasurement = useCallback(async () => {
			try {
				(
					(
						fromPage === 'price' ||
						fromPage === 'amount' ||
						fromPage === 'unit' ||
						fromPage === 'changeUnitForPrice' ||
						fromPage === 'changeUnitForPricesArr' ||
						fromPage === 'changeUnitForPricesArrTemplate' ||
						fromPage === 'changeUnitForAmount' ||
						fromPage === 'changeUnitForTemplates' ||
						fromPage === 'unitGroups' ||
						fromPage === 'ChangeUnitGroups' ||
						fromPage === 'changeUnitForTemplateDiscount'
					)
					&& addEditModalShow
				)
				&& await getLists()

				if (fromPage === 'changeTermsForTemplate') await getTerms()
				else if (fromPage === 'changeUnitForUnit' || fromPage === 'ChangeUnitGroups') await getLists()
				else if (fromPage === 'changeUnitForAmount') await getLists()
				else if (fromPage === 'changeHTMLSForTemplate') await getHtmls()
				else if (fromPage === 'changeMarkForMark' || fromPage === 'changeMarKForModalCreate') await getMark()
				else if (fromPage === 'changeTransportCategoryForModel') await getTransportCategory()
				else if (fromPage === 'changeBodyTypeForModel') await getBodyType()
			} catch (e) {
				setErrorMessage(e)
			}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [addEditModalShow]
	)

	const getLists = async () => {
		dispatch(setUnitsMeasurementList(unitlist))
		dispatch(getUnitGroupsList(unitgroups))

		// const {terms} = await db.terms.get('terms')
		// dispatch(getTermsList(terms))
	}

	useEffect(() => {
		getAllUnitsMeasurement()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addEditModalShow, fromPage === 'price'])

	const closeModalChangeUnitForPrice = () => {
		fromPage !== 'changeUnitForPricesArrTemplate' && dispatch(showChangeUnitPriceModal(false))
		dispatch(showChangeUnitAmountModal(false))
		fromPage !== 'ChangeUnitGroups' && dispatch(showChangeUnitModalTemplates(false))
		dispatch(showChangeUnitModal(false))
		dispatch(showChangeTermsModal(false))
		dispatch(showChangeHTMLSModal(false))
		dispatch(showChangeUnitGroupsModal(false))
		fromPage === 'changeUnitForUnit' && dispatch(showChangeUnitGroupModalHandler(false))
		fromPage === 'changeUnitForTemplates' && dispatch(showChangeUnitGroupModalHandler(false))
		fromPage === 'changeMarkForModel' && dispatch(showChangeMark(false))
		fromPage === 'changeMarKForModalCreate' && dispatch(showChangeMarkForModelModel(false))
		fromPage === 'changeTransportCategoryForModel' && dispatch(showChangeTransportCategory(false))
		fromPage === 'changeBodyTypeForModel' && dispatch(showChangeBodyType(false))
		fromPage === 'changeUnitForPricesArrTemplate' && dispatch(showModalSubChangeUnitPrice(false))
		setShowModalAmount !== undefined && setShowModalAmount(false)
		setShowModalPrice !== undefined && setShowModalPrice()
	}

	useEffect(() => {
		const teh = arrayBreakdownHelper(bigArrElements, countShowTerms)
		setSmallAllArrTerms([[], ...teh])
		setCountShowPage(teh?.length)
		setShowSmallTermArr(teh[0])
	}, [bigArrElements, countShowTerms, fromPage])

	useEffect(() => {
	}, [fromPage])

	useEffect(() => {
		showNextPagePagination()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage])

	const showNextPagePagination = () => {
		setShowSmallTermArr(smallAllArrTerms[currentPage])
	}
	const searchHandler = () => {
		console.log("Search");
		setLoaderSorting(true)
		if (fromPage === 'term') {
			const itemsDate = markerChangeTab === 'all' ? searchItemsHelper(bigArrElements, 'title', searchTermValue) : searchItemsHelper(arrForSearchInChangeTab, 'title', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'unit') {
			const itemsDate = searchItemsHelper(bigArrElements, 'term', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		}else if (fromPage === 'changeUnitForTemplateDiscount') {
			const itemsDate = searchItemsHelper(bigArrElements, 'term', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'price') {
			const itemsDate = searchItemsHelper(bigArrElements, 'tag', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'amount') {
			const itemsDate = searchItemsHelper(bigArrElements, 'name', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'changeUnitForPrice') {
			let itemsDate = searchItemsHelper(bigArrElements, 'term', searchTermValue)
			const itemsDateN = searchItemsHelper(bigArrElements, 'tag', searchTermValue)
			const res = itemsDate?.length === 0 ? itemsDateN : itemsDate
			setShowSmallTermArr(res)
			setCounterAllItems(res?.length) 
		} else if (fromPage === 'changeUnitForPricesArrTemplate') {
			let itemsDate = searchItemsHelper(bigArrElements, 'term', searchTermValue)
			const itemsDateN = searchItemsHelper(bigArrElements, 'tag', searchTermValue)
			const res = itemsDate?.length === 0 ? itemsDateN : itemsDate
			setShowSmallTermArr(res)
			setCounterAllItems(res?.length)
		} else if (fromPage === 'changeUnitForAmount') {
			const itemsDate = searchItemsHelper(bigArrElements, 'term', searchTermValue)
			const itemsDateN = searchItemsHelper(bigArrElements, 'name', searchTermValue)
			const res = itemsDate?.length === 0 ? itemsDateN : itemsDate
			setShowSmallTermArr(res)
			setCounterAllItems(res?.length)
		} else if (fromPage === 'changeUnitForTemplates') {
			const itemsDate = searchItemsHelper(bigArrElements, 'nameUnit', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'changeUnitForTemplatesSelect') {
			const itemsDate = searchItemsHelper(bigArrElements, 'nameModal', searchTermValue)
			const itemsDate1 = searchItemsHelper(bigArrElements, 'nameSelect', searchTermValue)
			const res = itemsDate?.length === 0 ? itemsDate1 : itemsDate
			setShowSmallTermArr(res)
			setCounterAllItems(res?.length)
		} else if (fromPage === 'amount') {
			const itemsDate = searchItemsHelper(bigArrElements, 'name', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'htmls') {
			const itemsDate = searchItemsHelper(bigArrElements, 'body', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'pages') {
			const itemsDate = searchItemsHelper(bigArrElements, 'url', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'changeTermsForTemplate') {
			const itemsDate = searchItemsHelper(bigArrElements, 'title', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'changeUnitForUnit') {
			const itemsDate = searchItemsHelper(bigArrElements, 'term', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'template') {
			const itemsDate = searchItemsHelper(bigArrElements, 'name', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'category') {
			const itemsDate = searchItemsHelper(bigArrElements, 'name', searchTermValue)
			const itemsDateInt = searchItemsHelper(bigArrElements, 'id', searchTermValue)
			
			setShowSmallTermArr([...itemsDate, ...itemsDateInt])
			setCounterAllItems([...itemsDate, ...itemsDateInt]?.length)
		} else if (fromPage === 'subcategory') {
			const itemsDate = searchItemsHelper(bigArrElements, 'title', searchTermValue)
			const itemsDateInt = searchItemsHelper(bigArrElements, 'id', searchTermValue)
			setShowSmallTermArr([...itemsDate, ...itemsDateInt])
			setCounterAllItems([...itemsDate, ...itemsDateInt]?.length)
		} else if (fromPage === 'subcategory1') {
			const itemsDate = searchItemsHelper(bigArrElements, 'title', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'subcategory2') {
			const itemsDate = searchItemsHelper(bigArrElements, 'title', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'mark') {
			const itemsDate = searchItemsHelper(bigArrElements, 'model', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'model') {
			const itemsDate = searchItemsHelper(bigArrElements, 'model', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'transportCategory') {
			const itemsDate = searchItemsHelper(bigArrElements, 'transport', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		} else if (fromPage === 'bodyType') {
			const itemsDate = searchItemsHelper(bigArrElements, 'transport', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		}else if (fromPage === 'ChangeUnitGroups') {
			const itemsDate = searchItemsHelper(bigArrElements, 'term', searchTermValue)
			setShowSmallTermArr(itemsDate)
			setCounterAllItems(itemsDate?.length)
		}
		timerLoaderHandler()
		setShowPaginationBoard(false)
	}

	const clearSearchHandler = () => {
		toggleModalSearch(false)
		setLoaderSorting(true)
		valueSearchInputHandler('')
		const teh = arrayBreakdownHelper(bigArrElements, countShowTerms)
		setSmallAllArrTerms([[], ...teh])
		setShowSmallTermArr(teh[0])
		setCounterAllItems(bigArrElements?.length)
		setCurrentPage(1)
		timerLoaderHandler()
		setShowPaginationBoard(true)
		filteredTypeHandler(markerChangeTab)
	}

	const sortedForColumn = (column) => {
		setLoaderSorting(true)
		if (column === 'id') {
			const te = [...bigArrElements].sort((a, b) => a.id > b.id ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'editor') {
			const te = [...bigArrElements].sort((a, b) => a.editor > b.editor ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'psevdo') {
			const te = [...bigArrElements].sort((a, b) => a.psevdo > b.psevdo ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'termin') {
			const teh = arrayBreakdownHelper(bigArrElements, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'name') {
			const te = [...bigArrElements].sort((a, b) => a.name.ua > b.name.ua ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'type') {
			const te = [...bigArrElements].sort((a, b) => a.type > b.type ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		} else if (column === 'value') {
			const te = [...bigArrElements].sort((a, b) => a.value > b.value ? 1 : -1)
			const teh = arrayBreakdownHelper(te, countShowTerms)
			setSmallAllArrTerms([[], ...teh])
			setShowSmallTermArr(teh[0])
			setCurrentPage(1)
		}
		timerLoaderHandler()
	}
	const timerLoaderHandler = () => {
		setTimeout(() => setLoaderSorting(false), 1000)
	}
	useEffect(() => {
		setCounterAllItems(bigArrElements?.length)
	}, [bigArrElements])

	useEffect(() => {
		if (searchTermValue !== '' && showModalSearch) {
			showModalSearch && searchHandler()
			setShowSearchBeforeDel(false)
		}
		if (searchTermValue === '' && !showModalSearch) {
			clearSearchHandler()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showModalSearch, showSearchBeforeDel])

	const filteredTypeHandler = (changeType) => {
		setLoaderSorting(true)
		timerLoaderHandler()
		const teh = filteredTermsType(bigArrElements, changeType)
		setArrForSearchInChangeTab(teh)
		setCounterAllItems(teh?.length)
		const tehArrs = arrayBreakdownHelper(teh, countShowTerms)
		setSmallAllArrTerms([[], ...tehArrs])
		setCountShowPage(tehArrs?.length)
		setShowSmallTermArr(tehArrs[0])
		setCurrentPage(1)
		setMarkerChangeTab(changeType)
	}
	
	useEffect(() => {
		markerChangeTab === 'all' && setCounterAllItems(arrForSearchInChangeTab?.length)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [arrForSearchInChangeTab])

	const toggleButtonsType = (name) => {
		for (let i = 0; i < allButtonsType.length; i++) {
			if (allButtonsType[i].innerText === name) {
				allButtonsType[i].classList.add('term-types_active')
			} else {
				allButtonsType[i].classList.remove('term-types_active')
			}
		}
	}
	
	return (
		<div className='terms-wrapper'>
			{
				errorMessage
				&& <AlertModal
					content={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			}
			{(fromPage === 'subcategory' || fromPage === 'category' || fromPage === 'htmls') ?
				(
					<div className='absolute_button'>
						<Button onClick={()=>setConfirmModal(true)}>Sync to prod</Button>
						{
							confirmModal ? 
							<ConfirmModal 
								title={`${fromPage === "category" ? t('cc_copycat_confirm') : fromPage === "subcategory"? t('cc_copysubcat_confirm') : t('cc_copyhtml_confirm')} `}
								text={t('migrate_warning')}
								close={()=>setConfirmModal(false)}
								success={migrationToProd}
								isLoading={(isMigrationSubcategoriesLoading || isMigrationCategoriesLoading || isMigrationHtmlsLoading)}
							/> : null
						}
						{
							(successModal || typeof successModal === 'number') ? 
							<ConfirmModal 
								title={t('success')}
								text={`На prod перенесено ${successModal} ${fromPage === "category" ? "категорій" : fromPage === "subcategory"? "підкатегорій" : "html"}.`}
								close={()=>setSuccessModal(false)}
							/> : null
						}
					</div>
				) : null}
			{
				(
					fromPage !== 'changeUnitForPrice' &&
					fromPage !== 'changeUnitForPricesArr' &&
					fromPage !== 'changeUnitForAmount' &&
					fromPage !== 'changeUnitForTemplates' &&
					fromPage !== 'changeUnitForTemplatesSelect' &&
					fromPage !== 'changeUnitForUnit' &&
					fromPage !== 'changeTermsForTemplate' &&
					fromPage !== 'changeHTMLSForTemplate' &&
					fromPage !== 'ChangeUnitGroups' &&
					fromPage !== 'changeMarkForModel' &&
					fromPage !== 'changeMarKForModalCreate' &&
					fromPage !== 'changeBodyTypeForModel' &&
					fromPage !== 'changeUnitForPricesArrTemplate' &&
					fromPage !== 'changeTransportCategoryForModel' &&
					fromPage !== 'changeUnitForTemplateDiscount'
				) && <AddButton
					title={t('create')}
					handler={showModalHandler}
				/>
			}
			<div className='d-flex align-items-center'>
				{
					(
						fromPage === 'changeUnitForPrice' ||
						fromPage === 'changeUnitForPricesArr' ||
						fromPage === 'changeUnitForAmount' ||
						fromPage === 'changeUnitForTemplates' ||
						fromPage === 'changeUnitForTemplatesSelect' ||
						fromPage === 'changeUnitForUnit' ||
						fromPage === 'changeTermsForTemplate' ||
						fromPage === 'changeHTMLSForTemplate' ||
						fromPage === 'ChangeUnitGroups' ||
						fromPage === 'changeMarkForModel' ||
						fromPage === 'changeMarKForModalCreate' ||
						fromPage === 'changeBodyTypeForModel' ||
						fromPage === 'changeUnitForPricesArrTemplate' ||
						fromPage === 'changeTransportCategoryForModel' ||
						fromPage === 'changeUnitForTemplateDiscount'
					)
					&&
					<AddButton
						title={t('create')}
						handler={showModalAddUnitHandler}
					/>
				}
				{
					(
						fromPage === 'changeUnitForPrice' ||
						fromPage === 'changeUnitForAmount' ||
						fromPage === 'changeUnitForPricesArr' ||
						fromPage === 'changeUnitForTemplates' ||
						fromPage === 'changeUnitForTemplatesSelect' ||
						fromPage === 'changeUnitForUnit' ||
						fromPage === 'changeTermsForTemplate' ||
						fromPage === 'changeHTMLSForTemplate' ||
						fromPage === 'ChangeUnitGroups' ||
						fromPage === 'changeMarkForModel' ||
						fromPage === 'changeMarKForModalCreate' ||
						fromPage === 'changeBodyTypeForModel' ||
						fromPage === 'changeUnitForPricesArrTemplate' ||
						fromPage === 'changeTransportCategoryForModel' ||
						fromPage === 'changeUnitForTemplateDiscount'
					) && <AddButton
						title={t('add')}
						handler={closeModalChangeUnitForPrice}
					/>
				}
			</div >
			{
				fromPage === 'term'
				&&
				<div className='terms-wrapper_search-types'>
				<button
					type='button'
					className='terms-wrapper_search-types_button term-types_active'
					onClick={() => {
						clearSearchHandler()
						filteredTypeHandler('all')
						toggleButtonsType(`${ARE_COMMON?.ALL}`)
					}}
				>
					{ARE_COMMON?.ALL}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={() => {
						filteredTypeHandler('interface')
						toggleButtonsType(`${ARE_COMMON?.INTERFACE}`)
					}}
				>
					{ARE_COMMON?.INTERFACE}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						filteredTypeHandler('select')
						toggleButtonsType(`${ARE_COMMON?.SELECT}`)
					}}
				>
					{ARE_COMMON?.SELECT}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						filteredTypeHandler('email')
						toggleButtonsType(`${ARE_COMMON?.EMAIL}`)
					}}
				>
					{ARE_COMMON?.EMAIL}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						filteredTypeHandler('deals_notification')
						toggleButtonsType(`${ARE_COMMON?.DEALS_NOTIFICATION}`)
					}}
				>
					{ARE_COMMON?.DEALS_NOTIFICATION}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						filteredTypeHandler('system')
						toggleButtonsType(`${ARE_COMMON?.SYSTEM}`)
					}}
				>
					{ARE_COMMON?.SYSTEM}
				</button >
				<button
					type='button'
					className='terms-wrapper_search-types_button'
					onClick={(e) => {
						filteredTypeHandler('error')
						toggleButtonsType(`${ARE_COMMON?.ERROR}`)
					}}
				>
					{ARE_COMMON?.ERROR}
				</button >
			</div >
			}
			<div className='terms-wrapper_search'>

				<SearchItemsInput
					toggleModalSearch={toggleModalSearch}
					searchTermValue={searchTermValue}
					valueSearchInputHandler={valueSearchInputHandler}
					searchHandler={searchHandler}
					clearSearchHandler={clearSearchHandler}
				/>

				<SelectShowRecords setCountShow={setCountShowTerms} />

			</div >
			{
				countShowPage > 1
				&&
				showPaginationBoard && <PaginationCustom
					itemsCount={countShowPage}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					alwaysShown={showPaginationBoard}
				/>
			}
			{
				isLoading || isLoadingReq
					?
					<LoaderContent />
					:
					<>
						{
							(
								fromPage === 'template'
							)
								?
								<TemplateTabl
									countShowPage={counterAllItems}
									setOpenEditModal={openEditModalHandler}
									toggleDeleteModal={toggleDeleteModal}
									sortedForColumn={sortedForColumn}
									countShowTerms={countShowTerms}
									showSmallTermArr={showSmallTermArr}
									fromPage={fromPage}
								/>
								:
								// counterAllItems
								// 	?
									<TermsTabl
										countShowPage={counterAllItems}
										setOpenEditModal={openEditModalHandler}
										toggleDeleteModal={toggleDeleteModal}
										sortedForColumn={sortedForColumn}
										countShowTerms={countShowTerms}
										showSmallTermArr={showSmallTermArr}
										fromPage={fromPage}
										setOrder={setOrder}
										setItem={setItem}
									/>
									// :
									// <p className='userInfo-wrapper_block-pib_name mt-5'>За запросом нічого немає</p >
						}
					</>
			}
		</div >
	)
}

export default ControlPanels
